import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmaWave2 from "src/components/nonbmaWave2"



const wavelpData2 = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`

body {
	font-family: 'Roboto', sans-serif;
}
       .footerlinks-module--br {
         display: none!important;
       }

       .btf_cb-module--btfCb button.btf_cb-module--install {
         background: #39b54a;
         padding: 20px;
         font-size: 30px;
         border-radius: 32px;
         box-shadow: 0 10px 20px -10px #23e332;
         margin-bottom: 25px;
       }

       #ctadisclosuresearch-module--ctadisclaimersearch {
         width: 80%;
         margin: 0 auto;
       }

       .wave-module--wave button.wave-module--waveBtn{
       	animation: 1.5s normal 0.5s 1;
        animation-name: slideInFromLeft;
       	animation-fill-mode: forwards;

       }
       @keyframes slideInFromLeft {
       	from {
       		background-position: right bottom;
       	}
       	to {
       		background-position: 0 0;
       		background-color: #096bfb;
       	}
       }

       .wave-module--wave h1 {
        font-weight: normal;
        font-size: 1rem;
        width: 640px;
        margin-bottom: 2rem;
        margin:175px auto 2rem;  
       }

       .wave-module--wave ul.wave-module--bullets {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 640px;
        margin: 0 auto;
        padding: 0;
        list-style: none;
      }

      .wave-module--wave ul.wave-module--bullets li {
        text-align: left;
        margin: 0;
        padding-left: 24px;
        position: relative;
        font-size: .85rem;
      }

      .wave-module--wave ul.wave-module--bullets li:before {
        content: "•";
        position: absolute;
        left: 8px;
        color: #333;
      }

      .wave-module--wave .progress-steps {
        display: flex;
        width: 640px;
        margin: 0 auto 2rem;
        position: relative;
        justify-content: space-between;
        position: absolute;
        top: 150px;
        left: 50%;
        transform: translateX(-50%);
        gap: 26px;
      }

      .wave-module--wave .progress-steps::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 2px;
        background: #e6e9f0;
        z-index: 1;
      }

      .wave-module--wave .progress-step {
        background: #dbdbdb;
        color: #3d3d3d;
        padding: 12px 20px;
        position: relative;
        z-index: 2;
        font-size: 1rem;
        white-space: nowrap;
        flex: 1;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 48px;
      }

      .wave-module--wave .progress-step:after {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        height: 0;
        width: 0;
        border-left: 20px solid #dbdbdb;
        border-top: 22px solid transparent;
        border-bottom: 26px solid transparent;
        z-index: 2;
      }

      .wave-module--wave .progress-step:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 0;
        width: 0;
        border-left: 20px solid white;
        border-top: 22px solid transparent;
        border-bottom: 22px solid transparent;
      }

      .wave-module--wave .progress-step:first-child:before {
        display: none;
      }

      .wave-module--wave .progress-step:last-child:after {
        display: none;
      }

      .wave-module--wave .progress-step.active {
        background: #4CAF50;
        animation: pulse 2s ease-in-out infinite;
        color: white;
      }

      .wave-module--wave .progress-step.active:after {
        border-left-color: #4CAF50;
        animation: pulseArrow 2s ease-in-out infinite;
      }

      .wave-module--wave .progress-step:first-child {
        padding-left: 40px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        background: #4CAF50;
      }

      .wave-module--wave .progress-step:last-child {
        padding-right: 40px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      @keyframes pulse {
        0% {
          background: #39b54a;
        }
        50% {
          background: #6cec7e;
        }
        100% {
          background: #39b54a;
        }
      }

      @keyframes pulseArrow {
        0% {
          border-left-color: #39b54a;
        }
        50% {
          border-left-color: #6cec7e;
        }
        100% {
          border-left-color: #39b54a;
        }
      }

      .wave-module--wave .progress-step:nth-child(2) {
        padding-left: 30px;
      }

      .wave-module--wave .progress-step:nth-child(3) {
        padding-left: 35px;
      }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>View any web page easily - Easy View</title></Helmet>
    <section>
      <div className="wave-module--wave">
        <div className="progress-steps">
          <div className="progress-step active">Download</div>
          <div className="progress-step">Run Installer</div>
          <div className="progress-step">Accept and Install</div>
        </div>
      </div>
      <NonbmaWave2 data={wavelpData2}></NonbmaWave2>
      </section>
    </HomepageLayout>
  )
}
